import React from 'react';
import './style.scss';

const Footer = () => {
    return (
        <footer>
            <div>
                <p>© Your Films. All right reserved</p>
            </div>
        </footer>
    );
};

export default Footer;